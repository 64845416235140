<div class="container" id="wrapper">
  <header class="header">
    <div class="row">
      <div
        class="col-12 col-lg-5 order-1 order-lg-0 d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0"
      >
        <a routerLink="/"><img src="/assets/images/logo.png" width="300" height="78" alt="" /></a>
      </div>
      <div
        class="col-12 col-lg-7 order-0 order-lg-1 d-flex flex-lg-column align-items-center justify-content-between align-items-lg-end header__panel"
      >
        <div class="d-inline d-lg-block">
          <span class="d-none d-lg-inline">{{ 'FlowLayout.help' | translate }}</span>
          <img src="/assets/images/icons/phone.svg" alt="" class="header__icon mx-1" />
          <a [href]="config.getSetting('tel_phone')" class="header__phone">{{ config.getSetting('phone') }}</a>
          <span class="d-none d-lg-inline ms-1"> {{ 'FlowLayout.helpOnline' | translate }}</span>
        </div>
        <button routerLink="/mypages/login" class="btn btn-gf btn-sm mt-lg-3 ms-4 ms-lg-0 header__button">
          <i class="fas fa-sign-in-alt"></i><span class="mx-1">{{ 'FlowLayout.login' | translate }}</span>
        </button>
      </div>
    </div>
  </header>

  <section id="content" class="content-box p-0">
    <router-outlet></router-outlet>
    @if (noticeVisibleSelector$ | async) {
      <app-notice></app-notice>
    }
  </section>

  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-5 d-none d-lg-flex align-self-center">
          <img src="/assets/images/logo.png" width="300" height="78" alt="" />
        </div>
        <div class="col-12 col-lg-7 footer__details">
          <address class="footer__address">
            <p>GF Money Consumer Finance AB</p>
            <div [innerHTML]="'FlowLayout.address' | translate"></div>
          </address>

          <nav class="footer__nav mt-1 mt-lg-0 ms-lg-15 ms-xl-20">
            <p [innerHTML]="'FlowLayout.openTime1' | translate"></p>
            <p>{{ 'FlowLayout.openTime2' | translate }}</p>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</div>
