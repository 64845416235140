import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http'
import { EMPTY, Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { AuthService } from './auth.service'
import { Injectable } from '@angular/core'
import { SharedService } from './shared.service'
import { environment } from '@environments/environment'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private sharedService: SharedService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers
        .append('version', '3.0.0')
        .append('service-id', environment.serviceId)
        .append('source', environment.appHost),
    })

    if (req.url.startsWith('/api/')) {
      req = req.clone({
        url: `${environment.apiHost}${req.url}`,
      })
    }

    if (req.url.includes('/api/')) {
      if (this.auth.isAuthenticated()) {
        req = req.clone({
          headers: req.headers.set('Token', /*istanbul ignore next*/ this.auth?.user?.token ?? ''),
        })

        // istanbul ignore next
        if (req.url.includes('/public/v1/track/navigation') || req.url.includes('/public/v1/track/data')) {
          req = req.clone({
            headers: req.headers.set('X-CUSTOMER', this.auth?.user?.id ?? ''),
          })
        }
      }
    }

    const loan = this.sharedService.getData('loan')
    if (loan) {
      req = req.clone({
        headers: req.headers.set('X-LOAN', loan.id),
      })
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.UnprocessableEntity) {
          this.router.navigateByUrl('/error')
          return EMPTY
        } else {
          if (error.status === HttpStatusCode.Forbidden) {
            this.auth.logout().subscribe(async () => await this.router.navigateByUrl('/mypages/login'))
          }
          return throwError(error)
        }
      }),
    )
  }
}
